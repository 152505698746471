import React from "react"
import { graphql } from "gatsby"
import {
  BlockContent,
  Kicker,
  Hero,
  Heading,
  Button,
  Section,
  Form,
  SEO,
  Image,
} from "components"

const LeadGenTemplate = ({ data }) => {
  const {
    kicker,
    title,
    image,
    day,
    month,
    year,
    _rawContent,
    slug,
    File,
  } = data.sanityLeadgen

  return (
    <>
      <SEO title={title} />

      <Section>
        <div className="grid lg:grid-cols-12 gap-16 items-center">
          <div className="space-y-6 lg:col-span-7 overflow-hidden relative">
            <div>
              <Kicker>{kicker}</Kicker>
              <Heading>{title}</Heading>
              <BlockContent blocks={_rawContent} />
            </div>
            <div className="relative aspect-ratio-square">
              <div className="absolute inset-0">
                <Image className="w-full h-full" source={image} />
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden lg:col-span-5 mb-8  rounded-sm shadow-soft">
            <Form
              lgpd
              name={`download -${slug.current}`}
              title=""
              description=""
              button="Download"
              download={File?.asset?.url}
            />
          </div>
        </div>
      </Section>
      {/* <Hero title={title} image={image && image} overlay />
      <div></div> */}
    </>
  )
}
export default LeadGenTemplate

export const query = graphql`
  query GET_LEADGEN($slug: String) {
    sanityLeadgen(slug: { current: { eq: $slug } }) {
      kicker
      title
      slug {
        current
      }
      File {
        asset {
          title
          url
        }
      }
      _rawContent
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
